import React from 'react'

function PersonalizedSpatial() {
    return (
        <>
            <div className="container-fluid bg-black">
                <div className="container pb-5">
                    <div className="row g-5 pt-3">
                        <div
                            className="col-lg-6 col-md-12 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                                <h1 className="display-2 text-white hfs48 pt-5 animated slideInDown">
                                    Constructing The Future, <b> like never before</b>
                                </h1>
                            </div>
                        </div>
                        <div
                            className="col-lg-6 col-md-12 wow fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="service-item position-relative h-100">
                                <div className="rounded">
                                    <p className="mb-4 pfs16-white">
                                        Our mission is to enable Humans to build infrastructure on Earth & Beyond.
                                        
                                        Augmenting human capabilities for next few generations & build a better future.
                                    </p>
                                    <p className="mb-4 pfs16-white">
                                        Augrade was started when we realised the future needs the Construction Industry to build more houses, data centers & infastructure amongst other things.
                                    </p>
                                    <p className="mb-4 pfs16-white">
                                        Our Vision will be realised over 3 Phases, first of which is re-inventing design/engineering & enabling you irrespective of your background to
                                        design your own homes or products & 3D print/construct those.
                                    </p>
                                
                                    
                                </div>
                            </div>
                        </div>
                        {/*<img
                            className="img-fluid pb-5"
                            src="img/personalize.png"
                            alt="augrade"
                        /> */}
                    </div>
                </div>
            </div>
        </>
    ) 
}

export default PersonalizedSpatial
