import React from 'react'
import Header from './Header'
import TheOnlyWay from './HomeComponents/TheOnlyWay';
import Video from './HomeComponents/Video';
import TrustedPeople from './HomeComponents/TrustedPeople';
import Industries from './HomeComponents/Industries';
import MergeDreamspace from './HomeComponents/MergeDreamspace';
import HowItWorks from './HomeComponents/HowItWorks';
import Delivering from './HomeComponents/Delivering';
import BringingTogether from './HomeComponents/BringingTogether';
import Gen1 from './DreamspaceComponents/Gen1';
import AugradeNews from './HomeComponents/AugradeNews';
import Workingdesktop from './HomeComponents/workingdesktop';
import Workingmobile from './HomeComponents/workingmobile';
import Dreamspace from './HomeComponents/dreamspaceintro';
import Team from './OurStoryComponents/Team';
import PersonalizedSpatial from './OurStoryComponents/PersonalizedSpatial';

export default function Home() {
  return (
    <div>
      <Header />

      <TheOnlyWay/> <br/>

       {/* <Video/> */ }

      <Workingdesktop/>

      <Workingmobile/>

     {/* <Delivering/> <br/><br/><br/> */}

      <Industries/> <br/><br/><br/>

      <PersonalizedSpatial /> <br/><br/><br/>

      <TrustedPeople/>

      {/* <HowItWorks/> */}


      {/* <BringingTogether/> */}

      <AugradeNews/>


    </div>
  );
}
