import React from 'react'
import { Link } from 'react-router-dom'
import KnowMore from '../ButtonComponent/KnowMore'

function Industries() {
    var classText="btn btn-primary button-right pfs24";
    return (
        <> 
            <div className="container-xxl py-5">
                <div className="container-fluid">
                <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="hfs40 mb-5">Use cases across projects</h2>
          </div>
                    <div className="row g-4">
                        <div
                            className="col-lg-4 col-md-6 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="service-item real-estate position-relative h-100">
                                <div className="rounded p-3">
                                    <h5 className="mb-3 pfs32">Residential</h5>
                                    <p className="mb-0 pfs20-white">
                                    Single-family homes, Multi-family dwellings or Complexes.
                                    </p>
                                    <br />
                                    <Link to="/RealEstate">
                                        <KnowMore classText={classText}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="service-item aerospace position-relative h-100">
                                <div className="rounded p-3">
                                    <h5 className="mb-3 pfs32">Industrial</h5>
                                    <p className="mb-0 pfs20-white">
                                    Factories, Data Center/Warehouses & Manufacturing facilities.
                                    </p>

                                    <Link to="/RealEstate">
                                        <KnowMore classText={classText}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 wow fadeInUp am"
                            data-wow-delay="0.1s"
                        >
                            <div className="service-item automobile position-relative h-100">
                                <div className="rounded p-3">
                                    <h5 className="mb-3 pfs32">Institutional</h5>
                                    <p className="mb-0 pfs20-white">
                                    Schools, Universities & Hospitals.
                                    </p>
                                    <br/>
                                    <Link to="/RealEstate">
                                        <KnowMore classText={classText}/>
                                    </Link> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Industries
