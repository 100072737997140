import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const TermsOfSubscription = () => {
  return (
    <Box sx={{ padding: 3 }}>
      {/* Title */}
      <Typography variant="h5" component="p" gutterBottom fontWeight="bold">
        TERMS OF SUBSCRIPTION
      </Typography>
      <Typography variant="body1" paragraph>
        Version 1.1.
      </Typography>
      <Typography variant="body1" paragraph>
        Effective from 01 August 2023
      </Typography>
      <Box sx={{ my: 2 }} />

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Augrade Dreamspace Solution is a proprietary software developed and
        owned by Augrade that enables the conversion of 2D drawings into 3D
        coordinated and code-compliant BIM designs, including material
        quantities and construction documentation, hosted on the cloud or
        on-premises and accessible via a web application or desktop application.
      </Typography>
      <Box sx={{ my: 2 }} />

      <Typography variant="body1" paragraph>
        These terms of subscription form a legally binding agreement
        (“Agreement”) between Augrade Inc., and Augrade Private Limited
        (collectively, “Augrade”) as one party, and the subscriber of the
        Augrade Dreamspace Solution (“you”, “Subscriber”) as the other party.
        This Agreement governs the conditions under which the Subscriber must
        access and use the Augrade Dreamspace Solution.
      </Typography>
      <Box sx={{ my: 2 }} />

      {/* Section 1 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Subscription to Augrade Dreamspace Solution
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Augrade offers subscription plans for the Augrade Dreamspace
                Solution with varying features, access levels, and durations.
                Details of the plan selected by the Subscriber, including
                pricing, are provided on Augrade’s website (www.augrade.com) or
                in its concerned invoice.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Augrade may, at its discretion, offer customised subscription
                plans based on specific Subscriber requirements. Such plans
                shall be governed by separate written agreements or the details
                stated in the invoices.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Augrade may offer a limited-duration trial or pilot
                subscription. Upon the conclusion of the trial period, the
                Subscriber must transition to a paid subscription to continue
                accessing the Augrade Dreamspace Solution. No warranties are
                offered by Augrade in respect of activities conducted by the
                Subscriber during the Trial or Pilot phase.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 2 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Fees and Payment
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber agrees to pay the subscription fees as specified
                in the applicable invoice. All fees are exclusive of applicable
                taxes, duties, or levies.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Subscription fees must be paid upfront and in full before the
                subscription is activated unless otherwise agreed in writing.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Subscription fees are non-refundable, except in cases where a
                refund is mandated by applicable law or agreed upon in writing
                by Augrade.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Delays in payment may result in suspension or termination of
                access to the Augrade Dreamspace Solution. Interest on overdue
                amounts may accrue at a rate of 1.5% per month or the maximum
                rate permitted by applicable law, whichever is lower.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 3 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Subscription Grant
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Subject to payment of fees, Augrade grants the Subscriber a
                temporary, non-exclusive, non-transferable, limited license to
                access and use the Augrade Dreamspace Solution for internal
                business purposes during the subscription term.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber shall not: (a) Share, sublicense, or resell the
                Augrade Dreamspace Solution; (b) Modify, reverse engineer, or
                create derivative works of the Augrade Dreamspace Solution, or
                attempt any of the foregoing actions; (c) Use the Augrade
                Dreamspace Solution in violation of applicable laws or
                regulations; (d) Breach any of the provisions of these Terms of
                Subscription.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 4 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Term and Termination
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The subscription term shall commence on the date stated in the
                invoice and shall continue for the duration specified in the
                applicable invoice.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Subscriptions may be renewed upon payment of the applicable fees
                for the renewal term. Renewal terms shall be subject to
                Augrade’s prevailing terms and conditions.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber may terminate the subscription at any time by
                providing prior written notice. However, no refunds will be
                provided for unused subscription periods.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Augrade may terminate the subscription immediately if: (a) The
                Subscriber breaches these Terms; (b) Required payments are not
                made; (c) The Subscriber engages in unauthorised use of the
                Augrade Dreamspace Solution.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Upon termination, the Subscriber’s access to the Augrade
                Dreamspace Solution shall cease, and all outstanding fees shall
                become immediately due. These effects are without prejudice to
                the legal rights of Augrade.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 5 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Intellectual Property Rights
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Augrade retains all intellectual property rights to the Augrade
                Dreamspace Solution and its underlying technology. The
                subscription does not transfer any ownership rights to the
                Subscriber.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber retains all ownership rights to the data provided
                to or generated within the Augrade Dreamspace Solution.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any feedback or suggestions provided by the Subscriber regarding
                the Augrade Dreamspace Solution shall become the exclusive
                property of Augrade.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The provisions of this clause shall survive the expiry or
                termination of the Agreement hereby formed.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 6 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Confidentiality
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Both parties agree to keep all Confidential Information
                disclosed during the subscription term confidential and to use
                such information only for the purposes of this Agreement.
                Confidential Information means any non-public, proprietary, or
                sensitive information disclosed by one party to the other during
                the term of the subscription or concerning the subscription.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Disclosure of Confidential Information is permitted only where
                required by law or with the prior written consent of the
                disclosing party.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The provisions of this clause shall survive the expiry or
                termination of the Agreement hereby formed.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 7 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Indemnification
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber agrees to indemnify, defend, and hold harmless
                Augrade, its affiliates, officers, directors, employees, agents,
                and licensors (collectively, the “Indemnified Parties”) from and
                against any and all claims, damages, losses, liabilities, costs,
                and expenses (including reasonable legal fees and disbursements)
                arising out of or in connection with:
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber’s use of the Augrade Dreamspace Solution in
                violation of this Agreement, applicable laws, or third-party
                rights;
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any breach by the Subscriber of its representations, warranties,
                or obligations under this Agreement;
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any claim that the Subscriber’s data, content, or materials
                provided to Augrade infringe upon or misappropriate the
                intellectual property rights, privacy rights, or other rights of
                any third party;
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any unauthorised use of the Augrade Dreamspace Solution by the
                Subscriber or its representatives; and
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any action or inaction by the Subscriber that results in a
                security breach, data loss, or other harm to Augrade or its
                systems.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 8 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Limitation of Liability
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                To the maximum extent permitted by law, Augrade’s total
                cumulative liability for all and any claim(s) arising out of or
                relating to these Terms shall not exceed the total subscription
                fees paid by the Subscriber during the preceding twelve (12)
                months.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                In no event shall Augrade be liable for indirect, incidental, or
                consequential damages, including but not limited to lost
                profits, business interruption, or loss of data.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The provisions of this clause shall survive the expiry or
                termination of the Agreement hereby formed.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 9 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Disclaimer of Availability
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Augrade Dreamspace Solution and all related services are
                provided on an “as is” and “as available” basis, without
                warranties of any kind, either express or implied. Augrade does
                not guarantee that the Augrade Dreamspace Solution will be
                uninterrupted, error-free, secure, or free from viruses or other
                harmful components.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                To the maximum extent permitted by applicable law, Augrade
                expressly disclaims all warranties, representations, and
                conditions, including but not limited to:
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Implied warranties of merchantability, fitness for a particular
                purpose, non-infringement, and quiet enjoyment;
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any warranties arising out of course of dealing, usage, or trade
                practice; and
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any guarantee that the Augrade Dreamspace Solution will meet the
                Subscriber’s requirements, operate without delays, or function
                in any specific environment.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                The Subscriber acknowledges that the availability of the Augrade
                Dreamspace Solution may be subject to limitations, delays, and
                other problems inherent in the use of technology, the internet,
                and electronic communications. Augrade shall not be liable for
                any interruptions, delivery failures, or damages resulting from
                such issues.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 10 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Governing Law and Dispute Resolution
          </Typography>
          <List component="ol" sx={{ listStyleType: "lower-alpha", pl: 4 }}>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                These Terms shall be governed by and construed in accordance
                with the laws of India.
              </Typography>
            </ListItem>
            <ListItem component="li" sx={{ display: "list-item" }}>
              <Typography variant="body1">
                Any disputes arising out of or in connection with these Terms
                shall be subject to the exclusive jurisdiction of the courts in
                Mumbai, Maharashtra, India.
              </Typography>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 11 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Amendments
          </Typography>
          <Typography variant="body1" paragraph>
            Augrade reserves the right to amend these Terms of Subscription at
            its sole discretion. The Subscriber shall be notified of any
            material changes by publishing the updated text of the Terms of
            Subscription on this page, and continued use of the Augrade
            Dreamspace Solution after the date of such publication constitutes
            acceptance of the updated Terms.
          </Typography>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 12 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Force Majeure
          </Typography>
          <Typography variant="body1" paragraph>
            Augrade shall not be held liable for deficiency, delays, or failure
            to perform obligations due to events beyond their reasonable
            control, including acts of God, natural disasters, pandemics, or
            government actions.
          </Typography>
        </ListItem>
      </List>
      <Box sx={{ my: 2 }} />

      {/* Section 13 */}
      <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
        <ListItem component="li" sx={{ display: "list-item" }}>
          <Typography variant="body1" fontWeight="bold">
            Correspondence Details
          </Typography>
          <Typography variant="body1" paragraph>
            Augrade may be contacted by any of the following means.
          </Typography>
          <Typography variant="body1" paragraph>
            If by email:
          </Typography>
          <Typography variant="body1" paragraph>
            Email: augrade0@gmail.com
          </Typography>
          <Typography variant="body1" paragraph>
            If by post in the USA:
          </Typography>
          <Typography variant="body1" paragraph>
            Augrade Inc.
            <br />
            95 3rd Street, San Francisco, CA 94013
          </Typography>
          <Typography variant="body1" paragraph>
            If by post in India:
          </Typography>
          <Typography variant="body1" paragraph>
            Augrade Private Limited
            <br />
            702/B, Krishna CHSL, Neelkanth Vihar Complex, Chembur, Mumbai –
            400089, MH, India
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

export default TermsOfSubscription;
