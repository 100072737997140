import React from 'react'
import TryForFree from '../ButtonComponent/TryForFree'
import BookCall from '../ButtonComponent/BookCall'

function TheOnlyWay() {
    var tryfreeclassText="btn btn-dark";
    var bookclassText="btn btn-primary";
    var onclickLink = () =>
        (window.location.href = "https://forms.gle/cSp2GHjgHdQMVRLD8");
    return (
        <> 
            {/* <div className="container-fluid bg-gray banner-first">
                <div className="container vertical-align-middle">
                    <div className="row">
                        <div
                            className="col-lg-8 wow fadeInUp first-text"
                            data-wow-delay="0.1s"
                        >
                            <h1 className="display-6 mb-4">
                                AI Native CAD Automation 
                            </h1>
                            <p className="pfs20">Convert 2D blueprints to accurate 3D CAD, ready for construction/production.</p>
                            <p className="pfs20">with Augrade's geometric & physics engine built in-house.</p>
                            <br />
                            <a target="_blank" href="https://forms.gle/cSp2GHjgHdQMVRLD8">
                                <TryForFree tryfreeclassText={tryfreeclassText}/>
                            </a>
                            {/* <Link to=""> 
                                <BookCall 
                                    bookclassText={bookclassText}
                                    onclickLink={onclickLink}
                                />
                            {/* </Link> 
                            <br />
                            <br />
                            <br />
                        </div>
                        
                    </div>

                        <div
                            className="col-lg-6 wow fadeInUp banner-img"
                            data-wow-delay="0.5s"
                        >
                            
                           <div className="flexrowdesktop">
                                <div className="col-md-6">
                                   <img
                                        className="img-fluid banimage1"
                                        src="img/banner-image1.png"
                                        style={{ marginTop: "-5px" }}
                                        alt="augrade"
                                    />
                                    <img
                                        className="img-fluid banimage3 mt-4"
                                        src="img/banner-image3.png"
                                        alt="augrade"
                                    />
                                    <img
                                        className="img-fluid banimage5 mt-4"
                                        src="img/banner-image5.png"
                                        alt="augrade"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <img
                                        className="img-fluid banimage2 mt-4"
                                        src="img/banner-image2.png"
                                        alt="augrade"
                                    />
                                    <img
                                        className="img-fluid banimage4 mt-4"
                                        src="img/banner-image4.png"
                                        alt="augrade"
                                    />
                                    <img
                                        className="img-fluid banimage6 mt-4"
                                        src="img/banner-image6.png"
                                        alt="augrade"
                                    />
                                </div>
                            </div>
    

                            <div className="flexrowmobile">
                                <img
                                    className="img-fluid banimage3 mt-4"
                                    src="img/banner-image3.png"
                                    alt="augrade"
                                />
                            </div>
    

                        </div>
                    </div>
                </div>
            */}
            <div className="container-fluid bg-gray">
        <div className="container vertical-align-middle banner-first">
          <div className="row pt-4 pb-4">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <p className="pfs20">Dreamspace Gen 1</p>
              <h1 className="display-5 mb-4">Concept to Construction</h1>
              <p className="pfs20">
                We automate design & documentation for projects of any sizes by converting 2D blueprints into code-compliant 3D BIM models + documents for permitting. 


              </p>

              <br />
              <button
                type="button"
                className="btn btn-dark"
                onClick={() =>
                  (window.location.href = "https://forms.gle/cSp2GHjgHdQMVRLD8")
                }
              >
                Partner with us<i className="bi bi-chevron-right ms-2"></i>
              </button>
            </div>

            <div
              className="col-lg-6 mt-5 mb-5 pt-5 pb-5 wow fadeInUp"
              data-wow-delay="0.5s"
            >
            
              <div className="embed-responsive embed-responsive-4by3">
                                    <iframe
                                        width="100%"
                                        height="350"
                                        src="https://www.youtube.com/embed/O-lwZw40D8o"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen
                                    ></iframe>
                </div>
            </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default TheOnlyWay
