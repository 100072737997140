import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import TermsOfSubscription from "./LegalPageComponents/LegalTerms";

const LegalPage = () => {
  const [selectedPage, setSelectedPage] = useState("Terms of Subscription");
  const [numPages, setNumPages] = useState(null);

  //   const [userLocation, setUserLocation] = useState(null);

  //   useEffect(() => {
  //     // Fetch user's location based on IP address
  //     fetch("https://ipapi.co/json/")
  //       .then(async (response) => response.json())
  //       .then((data) => {
  //         setUserLocation(data.country);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching location data:", error);
  //       });
  //   }, []);

  const handlePageChange = (page) => {
    setSelectedPage(page);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Array of pages with their respective content
  const pages = [
    {
      id: "Terms of Subscription",
      title: "Terms of Subscription",
      subtitle: "Last updated: January 2025",
      content: (
        <>
          <Typography paragraph>
            By logging into your account, you agree to be bound by our Terms and
            Conditions.
          </Typography>
          <Typography paragraph>
            This agreement sets out the terms and conditions on which we provide
            our services.
          </Typography>
        </>
      ),
      child: <TermsOfSubscription />,
    },
    // Add other pages here if needed
  ];

  const currentPage = pages.find((page) => page.id === selectedPage);

  return (
    <Box
      sx={{
        overflowY: "auto", // Enable vertical scrolling
        ml: "40px",
      }}
    >
      {/*  <Box
        sx={{
          pt: 8, // Adjust top padding as needed
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {currentPage && (
          <>
            <Typography variant="h4" gutterBottom>
              {currentPage.title}
            </Typography>
            {currentPage.subtitle && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                {currentPage.subtitle}
              </Typography>
            )}
            {currentPage.content}
          </>
        )}
      </Box> */}
      <Box display="flex" flexDirection="row">
        {/* Sidebar */}
        <Box
          sx={{
            mt: 8, // Adjust top margin as needed
            p: 2,
            width: 450,
            backgroundColor: "#f9f9f9",
            zIndex: 1, // Ensure it stays above other content
          }}
        >
          <List>
            {pages.map((page) => (
              <ListItem key={page.id} disablePadding sx={{ mb: 1 }}>
                <ListItemButton
                  onClick={() => handlePageChange(page.id)}
                  selected={selectedPage === page.id}
                  sx={{
                    borderRadius: 1,
                    py: 1.5,
                    "&.Mui-selected": {
                      "&:hover": {},
                    },
                    "&:hover": {
                      backgroundColor: "action.hover",
                    },
                    transition: "background-color 0.2s ease-in-out",
                  }}
                >
                  <ListItemText
                    primary={page.title}
                    primaryTypographyProps={{
                      variant: "body1",
                      fontWeight: selectedPage === page.id ? "bold" : "normal",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            pt: 8, // Adjust top padding as needed
            display: "flex",
            flexDirection: "column",
          }}
        >
          {currentPage && currentPage.child}
        </Box>
      </Box>
    </Box>
  );
};

export default LegalPage;
