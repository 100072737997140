import React from 'react'

function Workingdesktop() {
    return (
        <> 
            <div className="desktoptable">
                <div className="container mt-5 pt-4">
                    {/* <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s"> 
                        <h1 className="display-5 mb-4 hfs40roboto">How it works</h1>
                    </div>*/}
                    <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h2 className="hfs40 mb-5">How it works?</h2>
          </div>

                    <div className="table-wrap">
                        <table className="table table-responsive table-borderless">
                            <tbody>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center"> 
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                         
                                    </td>
                                    <td>
                                    <div className="pfs28roboto">Describe & Upload</div>
                                    <p classname="pfs20">AI Powered BIM + BOQ + CD Generation</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                                Describe your requirements & upload all relevant files which contain more details on the project.
                                                <br></br>Our AI will generate building code-compliant 3D BIM models + BOqs & CDs in required formats within minutes/hours.
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                         
                                    </td>
                                    <td>
                                    <div className="pfs28roboto">Edit, Analyse & Customise</div>
                                    <p classname="pfs20">Make changes parametrically</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                            Using Augrade's AI powered parametric editing capabilities you can edit & see the effects in real-time. Additionally you can choose from multiple options available that best fits your requirements.
                                                
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="align-middle alert border-bottom" role="alert">
                                    <td className="text-center">
                                        {/* <img 
                                            className="pic img-fluid"
                                            src="img/dreamspace1.png"
                                            alt="augrade"
                                        />*/}
                                        
                                    </td>
                                    <td>
                                        <div className="pfs28roboto">Effective Collaboration</div>
                                        <p classname="pfs20">Browser or Desktop, Real-time & Integration with 10+ tools</p>
                                    </td>
                                    <td>
                                        <div>
                                            <p className="pfs20">
                                            We enable multiple teams to interact & use design files across any tool in real time.               
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Workingdesktop

