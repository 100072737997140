import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid footer wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col-lg-3 col-md-12">
              <img
                src="img/footer-logo.png"
                className="img-fluid"
                alt="augrade"
              />
            </div>
            <div className="col-lg-9 col-md-12"></div>
          </div>
          <div className="row g-5 d-flex justify-content-between">
            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4">Explore</h4>
              <Link className="btn btn-link" to="/OurStory">
                About Augrade
              </Link>
              <Link className="btn btn-link" to="/NewsRoom">
                Newsroom
              </Link>
              <Link className="btn btn-link" to="/Career">
                Career
              </Link>
              <Link className="btn btn-link" to="/ContactUs">
                Support
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="mb-4 mt-4">Products</h4>
              <Link className="btn btn-link" to="/Dreamspace">
                Augrade Dreamspace
              </Link>
            </div>
            {/*<div className="col-lg-3 col-md-6">
                      <h4 className="mb-4">Help</h4>
                      <Link className="btn btn-link" to="/ContactUs">Support</Link>
                      <Link className="btn btn-link" to="/BlogPage">Blog Page</Link>
                        <Link className="btn btn-link" to="/SwPricing">Dreamspace Pricing</Link>  
                      <Link to="" className="btn btn-link" data-toggle="modal" data-target="#exampleModal"> Dreamspace Pricing</Link>
                      <Link to="" className="btn btn-link" data-toggle="modal" data-target="#exampleModal"> Merge Pricing</Link>
                      <Link className="btn btn-link" to="/MergePricing">Merge Pricing</Link>  
                  </div> */}
            <div className="col-lg-3 col-md-6 ">
              <h4 className="mb-4">Augrade Inc</h4>
              <p>Augment human capabilities by automating manual tasks</p>
              <div className="d-flex pt-3 text-center">
                {/* <Link
                  className="btn btn-square btn-dark rounded-circle me-2"
                  to=""
                >
                  <i className="fab fa-facebook-f"></i>
                </Link> */}
                <a
                  className="btn btn-square btn-dark rounded-circle me-2"
                  href="https://www.linkedin.com/company/augrade/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  className="btn btn-square btn-dark rounded-circle me-2"
                  href="https://x.com/augrade_inc"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                {/* <Link
                  className="btn btn-square btn-dark rounded-circle me-2"
                  to=""
                >
                  <i className="fab fa-instagram"></i>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <Link className="" to="/">
                All Rights Reserved 2023 @ Augrade Pvt. Ltd.
              </Link>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <a target="_blank" href="https://www.augrade.com/msa">
                Terms Of Use
              </a>{" "}
              |{" "}
              <a target="_blank" href="https://www.augrade.com/msa">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
      <a href="#" className="top">
        {" "}
        &#8593;
      </a>
      {/* <Link to="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i
            className="bi bi-arrow-up"></i></Link> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Contact Us
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-body">
        ...
      </div> */}
            <div className="modal-footer text-center pt-5">
              <a href="https://forms.gle/cSp2GHjgHdQMVRLD8" target="_blank">
                <button type="button" className="btn btn-dark">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalcoming"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content pb-5">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                &nbsp;&nbsp;
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center pt-5">
              <h1>Coming Soon</h1>
            </div>
            {/* <div className="modal-footer text-center pt-5">
        
        <button type="button" className="btn btn-dark" data-dismiss="modal">Contact Us</button>
      </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
